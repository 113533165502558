import React from "react";
import { MDXProvider } from "@mdx-js/react";

import Layout from "./layout";
import ReviewHeader from "./reviewHeader";
import ReviewFooter from "./reviewFooter";

const components = { 
    h1: props => 
        <div className="container mx-auto px-2">
            <div {...props} className="flex mt-2 mx-2 font-extrabold text-3xl sm:text-lg xs:text-xl text-black"></div>
        </div>,
    p: props => 
        <div className="container mx-auto px-2">
            <div {...props} className="mx-2 text-lg sm:text-base xs:text-base text-black text-opacity-75"></div>
        </div>,
    ReviewHeader,
    ReviewFooter,
    ul: props =>
        <div className="container mx-auto px-2">
            <div {...props} className="mx-2 text-lg sm:text-base xs:text-base text-black text-opacity-75"></div>
        </div>
    ,
}

const ReviewPage = ({children}) => {

    return (
        <MDXProvider components={components}>
            <Layout>
                {children}
            </Layout>
        </MDXProvider>
    )
}
export default ReviewPage