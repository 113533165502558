import React from "react";

import { Dollar, Eco, Excited, Fish, Ginger, Meh, Rice, Sad, Sauce, Scale, SlightSmile, Smile, Star, Toppings, Question, X, Down, Up} from "./svg/icons";
import { PokeMegRater } from "./svg/pokeMegIcons";

const ReviewHeader = ({data}) => {

    const [showModal, setShowModal] = React.useState(false);

    const fieldMap = {
        "Overall": "overall",
        "Extra Ginger": "extraGinger",
        "Price": "price",
        "Quantity": "quantity",
        "Fish": "fish",
        "Rice": "rice",
        "Toppings": "toppings",
        "Sauce": "sauce",
        "Eco-friendly": "eco-friendly",
    }

    const Table = () => (
        <div className="grid grid-flow-row grid-cols-3 justify-center w-full self-center">
            <Tile name="Overall"/>
            <Tile name="Extra Ginger"/>
            <Tile name="Price"/>
            <Tile name="Quantity"/>
            <Tile name="Fish"/>
            <Tile name="Rice"/>
            <Tile name="Toppings"/>
            <Tile name="Sauce"/>
            <Tile name="Eco-friendly"/>
        </div>
    )

    const Tile = ({name}) => {
        let field = null;
        if (name === "Price") {
            var formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            });
            field = (data[fieldMap[name]] ? formatter.format(data[fieldMap[name]]) : formatter.format(0));
        }
        else if (name === "Eco-friendly") {
            field = data[fieldMap[name]] ? data[fieldMap[name]] : "no";
        }
        else {
            field = data[fieldMap[name]] ? data[fieldMap[name]].toString().concat("/6") : "0/6";
        }
        let rating = (<div className="font-bold">{field}</div>);

        return (
            <div className="flex flex-col bg-white py-2  border border-black border-opacity-75">
                <div className="text-xs flex text-black text-opacity-75 justify-center pt-1">
                    {name}
                </div>
                <div className="flex flex-row text-blue items-center justify-center py-1">
                    <Icon name={name} className="mr-2"/>
                    {rating}
                </div>
            </div>
        )
    }

    const Icon = ({name, className}) => {
        className = `${className} h-6`
        switch(name) {
            case "Overall":
                let emoji = null;
                switch(parseInt(data[fieldMap[name]])) {
                    case 6:
                        emoji = (<Excited className={className}/>);
                        break;
                    case 5:
                    case 4: 
                        emoji = (<Smile className={className}/>);
                        break;
                    case 3:
                        emoji = (<SlightSmile className={className}/>);
                        break;
                    case 2:
                        emoji = (<Meh className={className}/>);
                        break;
                    case 1:
                        emoji = (<Sad className={className}/>);
                        break;
                    default: 
                        emoji = (<Smile className={className}/>);
                        break;
                }
                return (emoji)
            case "Extra Ginger":
                return (
                    <Ginger className={className}/>
                )
            case "Price":
                return (
                    <Dollar className={className}/>
                )
            case "Quantity":
                return (
                    <Scale className={className}/>
                )
            case "Fish":
                return (
                    <Fish className={className}/>
                )
            case "Rice":
                return (
                    <Rice className={className}/>
                )
            case "Toppings":
                return (
                    <Toppings className={className}/>
                )
            case "Sauce":
                return (
                    <Sauce className={className}/>
                )
            case "Eco-friendly":
                return (
                    <Eco className={className}/>
                )
            default:
                return (
                    <Star className={className}/>
                )
        }
    }

    const H1 = ({children}) => (
        <div className="text-black font-bold pl-2">
            {children}
        </div>
    );

    const H2 = ({children}) => (
        <div className="text-black text-opacity-75 pl-3">
            {children}
        </div>
    );

    const Modal = () => (
        <div class="fixed inset-0 w-full z-50 p-8" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            
            <div class="cursor-pointer fixed inset-0 bg-black bg-opacity-50 transition-opacity" aria-hidden="true" onClick={()=>setShowModal(false)}></div>
    
            <div className="flex h-full w-full justify-center">
                <div class="relative h-full bg-white overflow-y-auto shadow-xl transform transition-all w-1/2 sm:w-full xs:w-full">
                    <div className="flex flex-row sticky bg-blue top-0 justify-between py-2 px-4 text-white">
                        <div className="flex font-bold justify-center">
                            Rating System?
                        </div>
                        <div className="flex cursor-pointer" onClick={()=>setShowModal(false)} aria-hidden="true">
                            <X className="hover:text-black h-6"/>
                        </div>
                    </div>
                    <div className="p-2">
                        <H1>
                            6-stars?
                        </H1>
                        <H2>
                            Sometimes 5-stars just isn't enough. This is dedicated to Sian's invention of the 6 lime box rating system.
                        </H2>
                        <H1>
                            Category breakdown:
                        </H1>
                        <Section name="Overall">
                            Although maybe expected to be simply a cumulative score of the other categories, 
                            the "Overall" score is actually based on gut feels and remberances. It's a number 
                            that's not just about the numbers.
                        </Section>
                        <Section name="Extra Ginger">
                            Our most important category, for a life without ginger is no life at all. 
                            That is where we push the boundaries of awkwardness to see just how much extra 
                            ginger we can get.
                        </Section>
                        <Section name="Price">
                            Straight forward, the lower the price the better! We standardized this by 
                            ordering a bowl with two types of fish and no upcharges.
                        </Section>
                        <Section name="Quantity">
                            A scale ranging from 1 meaning "wow, that was barely a snack", to 6 meaning 
                            "For multiple reasons, I need to get out of these pants".
                        </Section>
                        <Section name="Fish">
                            This score takes into account both the quality and quantity of fish (heads up that these basic bithes
                            usually go for tuna and salmon).
                        </Section>
                        <Section name="Rice">
                            How good was the rice? Was it the right amount of rice? Would I eat it plain? If the answers were,
                            "Perfect. Yes. All damn day." Then you've got yourself a 6 out of 6.
                        </Section>
                        <Section name="Toppings">
                            All about variety, more stars mean more (good) options!
                        </Section>
                        <Section name="Sauce">
                            Sauce variety and quality are most important here
                            with bonus points for creativity.
                        </Section>
                        <Section name="Eco-friendly">
                            A black and white category, either we feel they are trying to be eco-friendly or not. 
                            Nothing ruins the mood like forgetting to look out for our #1 lady, Mother Earth.
                        </Section>
                    </div>
                    
                            
                </div>  
            </div>
            
            
        </div>
    );

    const Section = ({ name, children }) => {
        const [section, setSection] = React.useState(false);

        return (
            <div>
                <div className="flex w-full bg-blue bg-opacity-25 text-black px-2 my-1 justify-between" onClick={()=>setSection(!section)} aria-hidden="true">
                    {name}
                    <div>
                        {!section && (
                            <Down className="h-6"></Down>
                        )}
                        {section && (
                            <Up className="h-6"/>
                        )}
                    </div>
                </div>
                {section && (
                    <div className="px-2 py-1 text-black text-opacity-75">
                        {children}
                    </div>
                )}
            </div>
        )
    };

    let tailwind_bg = null;
    switch(data.background){
        case 'black':
            tailwind_bg = 'bg-black';
            break;
        case 'white':
            tailwind_bg = 'bg-white';
            break;
        default:
            tailwind_bg = 'bg-white';
            break;
    }

    return (
        <div className="flex flex-col">
            {showModal && (<Modal></Modal>)}
            <div className="container flex flex-row sm:flex-col xs:flex-col mx-auto px-4 justify-evenly sm:justify-center xs:justify-center mb-2">
                <div className="flex flex-col bg-blue shadow-lg w-2/5 xs:w-full p-2 mt-8 sm:mt-4 xs:mt-4">
                    <div className="flex text-3xl font-bold text-white justify-center pt-1">
                        {data.title}
                    </div>
                    <div className="flex text-md font-thin text-white text-opacity-75 justify-center pb-1">
                        {data.neighborhood}
                    </div>
                    <div className={`${tailwind_bg} flex items-center justify-center border border-black border-opacity-75`}>
                        <img
                            alt={data.title} 
                            src={require(`../images/${data.slug}/logo.png`)} 
                            className="h-40 m-2"
                        />
                    </div>
                </div>
                <div className="flex flex-col bg-blue shadow-lg w-2/5 xs:w-full p-2 mt-8 sm:mt-4 xs:mt-4">
                    <div className="flex flex-row w-full justify-between items-center p-2 text-white">
                        <div className="flex-grow bg-white h-px">

                        </div>
                        <div className="flex flex-row mx-2">
                            <div className="font-thin text-md">
                                The Breakdown
                            </div>
                            <div 
                                className="hover:text-black cursor-pointer"
                                onClick={()=>setShowModal(true)}
                                aria-hidden="true"
                            >
                                <Question className="h-6 ml-2"></Question>
                            </div>
                        </div>
                        <div className="flex-grow bg-white h-px">

                        </div>
                    </div>
                    <Table></Table>
                </div>
            </div>
            <div className="flex flex-row bg-blue bg-opacity-25  text-black font-bold text-4xl xs:text-xl pl-2 items-center shadow-md mt-14 xs:mt-5 py-2">
                <div className="container flex relative mx-auto px-4 justify-center sm:justify-start xs:justify-start">
                    More about this joint
                    <div className="absolute right-2 -top-14 xs:-top-5">
                        <PokeMegRater className="h-24 sm:h-14 xs:h-12 transform scale-x-flip"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReviewHeader