import React from "react";

const ReviewFooter = ({data}) => {

    const [show, setShow] = React.useState(false);
    const [src, setSrc] = React.useState(null);

    let photos = [`1`,`2`,`3`,`4`];

    const ImageTile = ({src}) => {
        if (src != null) {
            return (
                <div className="h-48 w-48 sm:h-28 sm:w-28 xs:h-28 xs:w-28 m-4 sm:m-2 xs:m-1 overflow-hidden">
                    <img
                        alt={data.title} 
                        src={src} 
                        className="cursor-pointer object-cover h-full w-full"
                        onClick={()=>{
                            setShow(true);
                            setSrc(src);
                        }}
                        aria-hidden="true"
                    />
                </div>
            );
        }
        else {
            return (
                <div></div>
            );
        }
    }

    const ImageView = ({src}) => (
        <div class="flex fixed inset-0 w-full z-50 justify-center items-center" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                
            <div class="cursor-pointer fixed inset-0 bg-black bg-opacity-50 transition-opacity" aria-hidden="true" onClick={()=>setShow(false)}></div>

            <img
                    src={src} 
                    alt={data.title}
                    className="object-contain xl:h-3/4 lg:h-3/4 md:h-3/4 sm:w-full xs:w-full overflow-y-auto transform transition-all m-8"
            />

        </div>
    );

    return (
        <div className="mt-4">
            { show && (
                <ImageView src={src}></ImageView>
            )}
            <div className="relative flex flex-col w-full text-black justify-between mt-2">
                <div className="flex justify-center py-2 text-4xl xs:text-xl font-bold bg-blue bg-opacity-25 shadow-md">
                    Photo Gallery
                </div>
                <div className="container flex flex-col mx-auto px-4 justify-center align-middle pt-4">
                    <div className="flex flex-wrap justify-center">
                        {photos.map((pic) => {
                            try {
                                let src = require(`../images/${data.slug}/${pic}.jpg`)
                                return (<ImageTile src={src}/>);
                            } catch(err) {
                                console.log(err);
                                return (<ImageTile src={null}/>);
                            }
                        })}
                    </div>
                </div>
            </div>

            <div className="container mx-auto px-4 my-4">

                <div className="flex h-px bg-blue bg-opacity-25 mb-2 mt-4"/>

                <div className="flex text-lg xs:text-base font-bold text-black text-opacity-75 justify-center pt-1">
                    <a  className="hover:text-orange focus:outline-none" href={(data.url ? data.url : null)}>
                        {data.title}
                    </a>
                </div>
                <div className="flex text-base xs:text-sm font-thin text-black text-opacity-75 justify-center py-1">
                    {data.address}
                </div>
            </div>
        </div>
    )
}

export default ReviewFooter