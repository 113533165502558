import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/reviewPage.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ReviewHeader = makeShortcode("ReviewHeader");
const ReviewFooter = makeShortcode("ReviewFooter");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ReviewHeader data={props.pageContext.frontmatter} mdxType="ReviewHeader" />
    <h1>{`Overall`}</h1>
    <p>{`There has been a lot hype surrounding this place, and honestly it nearly lives up to it.
While not our favorite, we would definitely recommend checking it out. Because while we don't
like the menu's limiting options, the ones they have are tasty and unique and that may
be good enough for you.`}</p>
    <h1>{`Key Points:`}</h1>
    <ul>
      <li parentName="ul">{`Big, high quaility chunks of fish. 😋`}</li>
      <li parentName="ul">{`Would not recommend the Izumidai.`}</li>
      <li parentName="ul">{`Small menu, but what they do have they make well.`}</li>
      <li parentName="ul">{`Unique toppings, unfortunately you have to pay extra for them. 😕`}</li>
      <li parentName="ul">{`Two sauce options; regular or spicy.`}</li>
    </ul>
    <h1>{`Biggest Suggestion`}</h1>
    <p>{`Step up the toppings and sauce options.`}</p>
    <ReviewFooter data={props.pageContext.frontmatter} mdxType="ReviewFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      